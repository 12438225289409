<template>
    <div>
        <h3 style="font-weight: bold; color: blue;">Relatório Aniversáriantes do mês</h3>
        <div>
            <div class="field">
                <div class="select is-link">
                    <p>Digite a Data: </p>
                    <select v-model="mes">
                        <option v-for="opMeses in opMeses" v-bind:value="opMeses.value">
                            {{ opMeses.text }}
                        </option>
                    </select>

                    <button @click="consultaMembro">Abre Tabela</button>
                    <button :disabled="!dados" @click="generatePDF">Gerar PDF</button>
                </div>
            </div>
            <hr />
            <div>
                <table class="table">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Data</th>
                            <th>Nome</th>
                            <th>CPF</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="dados in dados" v-bind:key="dados.id">
                            <td>{{ dados.id }}</td>
                            <td>{{ dados.nascimento }}</td>
                            <td>{{ dados.nome }}</td>
                            <td>{{ dados.cpf }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>

        </div>


    </div>
</template>

<script>
import jsPDF from 'jspdf';
import 'jspdf-autotable';
//import VueHtml2pdf from 'vue-html2pdf';
import axios from "axios"
import moment from 'moment';

export default {
    data() {
        return {

            mes: String,
            opMeses: [
                { text: 'Janeiro', value: '1' },
                { text: 'Fevereiro', value: '2' },
                { text: 'Março', value: '3' },
                { text: 'Abril', value: '4' },
                { text: 'Maio', value: '5' },
                { text: 'Junho', value: '6' },
                { text: 'Julho', value: '7' },
                { text: 'Agosto', value: '8' },
                { text: 'Setembro', value: '9' },
                { text: 'Outubro', value: '10' },
                { text: 'Novembro', value: '11' },
                { text: 'Dezembro', value: '12' }
            ],
            dados: null,
            data: null,
            url: process.env.VUE_APP_API_URL,
            port: process.env.VUE_APP_PORT
        };
    },

    methods: {

        consultaMembro() {
            
            console.log('Mes escolhido :  ' + this.mes)
            var mes =  this.mes;
            var url = this.url + ":" + this.port + "/aniversariantes/"+ mes;
            /* 
            fetch(url)
            .then(res => res.json())
            .then(d => this.dados = d);
                //      console.log(this.dados);
            let data = JSON.parse(JSON.stringify(this.dados));
            //console.log(data)
            this.dados = this.dados.map( item => ({nome: item.nome, cpf: item.cpf, nascimento: moment(item.nascimento).format('DD/MM/yyyy')}))
            //this.dados = data;
            this.data = data.map( (item) => [item.nascimento, item.nome, item.cpf])
             */                                
                    
            //* 
            axios.get(url, {})
            .then((res) => {
                let data = JSON.parse(JSON.stringify(res.data));
                console.log(data);
                data = data.map( item => ({nome: item.nome, cpf: item.cpf, nascimento: moment(item.nascimento).format('DD/MM/yyyy')}))
                console.log('data: ',data)
                this.dados = data;
                this.data = data.map( (item) => [item.nascimento, item.nome, item.cpf])
            }).catch((err) => {
                console.log(this.msg);
            });
            //*/
        },
        generatePDF() {
            const doc = new jsPDF();

            // Cabeçalho
            const title = "Assembleia de Deus - Central Rigoleto";
            const title2 = "Relatório de Aniversáriantes do Mês"
            const headers = [['Nascimento', 'Nome', 'CPF']];
            const footer = 'EMDL Soluções em Informática';

            // Adicionando o título
            doc.setFontSize(18);
            doc.text(title, 45, 20);
            doc.setFontSize(14);
            doc.text(title2, 60, 27);

            // Adicionando a tabela
            doc.autoTable({   
                head: headers,
                body: this.data,
                startY: 30
                /*                 
                didDrawPage: function (data) {
                    //Cabeçalho
                    doc.setFontSize(12);
                    doc.text('Cabeçalho do Relatório', data,settings.margin.left, 10 );

                    //rodapé
                    const pageCount = doc.internal.getNumberOfPages();
                    const totalPageExp = pageCount > 1 ?'Páginas: ' : 'Página: ';
                    const pageText = footer+' '+ totalPageExp+ '   '+pageCount;
                    doc.setFontSize(10);
                    doc.text(pageText, data.settings.margin.left, doc.internal.pageSize.height - 10);
                } */

  
            });

            
      
            // Salvando o PDF
            doc.save("relatorio.pdf");
        }

    }
}
</script>
<style scoped>
.elemento {
    padding-top: 5px;
    padding-right: 10px;
    padding-bottom: 7px;
    padding-left: 15px;
    margin: 10px;
}
</style>