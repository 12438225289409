<template>
  <div id="app">
    <myNav :logo="logo_src" :alt="app_name" />
    <div id="rota">
      <router-view/>
    </div>
    <myfooter />
  </div>
</template>
<script>
import myNav from './components/my-Nav.vue';
import myfooter from './components/my-footer.vue';

export default {
  components: {
    myNav,
    myfooter
  },
  data() {
    return {
      logo_src: "../../public/img/logo.png",
      app_name: "Sistema de Gestão de Igrejas"
      
    }
  },
  methods(){

  }
}
</script>

<style scoped>
 #app {
  display: flex;
  flex-direction: column;
 }

 #rota {
  min-height: calc(100vh - 200px);
  padding: 20px;
 }
</style>
 
